<template lang="pug">
  .lost-password-page
    .card(dis-hover)
      h2.text-center 404
      .m-t-25.flex.flex-x-center
        router-link(to="/" v-if="user && user.id") 返回首页
        router-link(to="/" v-else) 返回首页
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'NotFound',  // NotAccess、NotServer
  computed: {
    ...mapState({
      user: state => state.account,
    })
  },
}
</script>

<style lang="less" scoped>
.card {
  width: 400px;
  margin: -250px -200px;
  position: absolute;
  top: 50%; left: 50%;
}
</style>
